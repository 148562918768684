@import '../../colors.sass'
    
footer
    box-shadow: 0 0 10px 10px rgba(0,0,0,0.1)
    margin: 36px
    padding: 30px

    @media (max-width: 700px)
        margin: 30px 0
        padding: 24px 0

    h2
        text-align: center

    .contact-content
        display: flex
        margin-top: 30px
        padding: 0 64px
        justify-content: space-between

        @media (max-width: 700px)
            flex-direction: column
            align-items: center
            justify-content: space-between
            padding: 0 24px
            margin-top: 20px
            min-height: 200px
            text-align: center


        p
            max-width: 350px
            word-wrap: break-word
        
        a
            color: $lightBrown
            text-decoration: none

        .title
            margin-bottom: 8px
            color: $primary
            font-weight: 500
            font-size: 14px