.image-slider-section
    display: flex
    flex-direction: column
    margin: 48px 36px
    padding: 24px 18px
    text-align: center

    @media (max-width: 700px)
        margin: 24px 18px
        padding: 12px 9px
    h2
        margin-bottom: 48px

        @media (max-width: 700px)
            margin-bottom: 30px

    .images
        display: flex
        gap: 12px
        width: 100%
        min-height: 300px
        margin-bottom: 12px
        overflow: hidden

        @media (max-width: 700px)
            flex-direction: column
        img
            width: calc(100% / 3)

            @media (max-width: 700px)
                width: 100%
                margin: 10px 0

        .column
            display: flex
            gap: 12px
            height: 100%
            flex-direction: column
            img
                width: 100%
                height: 50%

            .sub-column
                display: flex
                width: 100%
                height: 100%
                gap: 12px

                @media (max-width: 700px)
                    flex-direction: column

                img
                    width: calc(50% - 6px)
                    @media (max-width: 700px)
                        width: 100%
