@import '../../colors.sass'

.why-us-section
    box-shadow: 0 0 10px 10px rgba(0,0,0,0.1)
    margin: 36px -220px

    @media (max-width: 700px)
        margin: 12px 0
    .container
        display: flex
        flex-direction: column
        padding: 24px
    .title-container
        display: flex
        flex-direction: column
        text-align: center

        p
            font-size: 14px
            color: $lightText
            font-weight: 400

    .services-container
        display: flex
        flex-wrap: wrap
        gap: 32px
        margin: 24px 48px

        @media (max-width: 700px)
            margin: 24px 0
            flex-direction: column
            align-items: center
        .service-card
            flex: 1 1 calc(33.333% - 32px)
            box-sizing: border-box
            max-width: calc(33.333% - 32px)
            display: flex
            flex-direction: column
            align-items: center
            text-align: center
            border: 1px solid $seperatorColor
            padding: 24px
            width: 220px
            @media (max-width: 700px)
                margin: 8px 0
                width: 100%
                max-width: 100%
            img
                width: 60px
                height: 60px


            .card-title
                font-weight: 600
                margin: 10px 0

            .card-text
                font-weight: 300
