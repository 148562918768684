@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap')
@import './colors.sass'

body 
  margin: 0
  font-family: 'Montserrat', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale


a
  text-decoration: none

ul
  padding: 0
  margin: 0
  list-style: none

h1,h2,h3,p
  margin: 0
  padding: 0

.container
  max-width: 1360px
  margin: 0 auto
  padding: 0 24px 36px 24px

h2
  color: $primary
  font-size: 28px
  font-weight: 600
  letter-spacing: 1.8px
  text-transform: uppercase
