@import '../../colors.sass'

header
    height: 108px
    
    .container
        height: 100%
        display: flex
        justify-content: space-between
        align-items: flex-end

    .logo
        color: $white
        width: 120px
        font-weight: 600
        font-size: 1.5em

    .menu
        margin-right: 156px
        margin-bottom: 10px
        display: flex
        justify-content: space-between
        li
            margin: 0 40px
            a
                font-size: 14px
                padding: 16px
                color: $white
                font-weight: 400
                text-transform: uppercase

    .menu-button-container
        display: none
        width: 30px
        cursor: pointer
        flex-direction: column
        justify-content: center
        align-items: center
        z-index: 15
        margin-bottom: 20px
        

    #menu-toggle 
        display: none
        

    .menu-button,
    .menu-button::before,
    .menu-button::after 
        display: block
        background-color: #fff
        position: absolute
        height: 4px
        width: 30px
        transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1)
        border-radius: 2px
    

    .menu-button::before 
        content: ''
        margin-top: -8px
    

    .menu-button::after 
        content: ''
        margin-top: 8px
    

    #menu-toggle:checked + .menu-button-container .menu-button::before 
        margin-top: 0px
        transform: rotate(405deg)
    

    #menu-toggle:checked + .menu-button-container .menu-button 
        background: rgba(255, 255, 255, 0)
    

    #menu-toggle:checked + .menu-button-container .menu-button::after 
        margin-top: 0px
        transform: rotate(-405deg)
    

    @media (max-width: 700px) 
        .menu-button-container
            display: flex
        
        .menu
            display: none
            position: absolute
            top: 0
            left: 0
            flex-direction: column
            width: 100%
            height: 100vh
            justify-content: center
            align-items: center
            background-color: $bgColor
            z-index: 10
        
        #menu-toggle:checked ~ .menu
            display: flex
        
        .menu > li
            position: relative
            display: flex
            justify-content: center
            margin: 0
            padding: 0.5em 0
            width: 100%
        
        
            