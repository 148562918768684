$white: #ffffff
$black: #000000

$primary: #EDD83D
$green: #35CE8D

$brown: #4a291b
$lightBrown: #efdac9
$orange: #ef4f0b

$bgColor: #454545


$textColor: $lightBrown
$lightText: $textColor
$seperatorColor: rgba($lightBrown, 0.2)