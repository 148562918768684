@import '../../colors.sass'

.hero-section
    position: relative
    height: 100vh
    background-image: url('../../assets/haf_8.jpg')
    background-position: center center
    background-repeat: no-repeat
    background-size: cover
    color: $white

    .left-mask
        position: absolute
        left: 0
        top: 0
        height: 100%
        background-color: rgba(0,0,0,0.3)
        width: 45%

    .mask
        position: absolute
        left: 0
        top: 0
        background-color: rgba(0,0,0,0.3)
        width: 100%
        height: 100%

    
    .container
        position: relative

    .hero-texts
        height: 60%
        display: flex
        flex-direction: column
        justify-content: center
        .small-text
            margin: 0
            font-weight: 300
            color: $white

        .main-text
            margin: 0
            padding: 0
            font-weight: 400
            font-size: clamp(2rem, 5vw, 9rem)
            line-height: clamp(2rem, 5vw, 9rem)
            letter-spacing: clamp(2rem, 5vw, 9rem)

@media (max-width: 700px)
    .hero-section
        background-image: url('../../assets/haf_8.jpg')
        background-position: top
        background-size: cover


