@import '../../colors.sass'
    
.about-us-section
    display: flex
    flex-direction: column
    margin: 48px 36px
    padding: 24px 18px

    @media (max-width: 700px)
        margin: 24px 18px
        padding: 18px 12px

    &__texts
        display: flex
        margin-bottom: 32px
        h2
            text-align: left
            margin-right: 200px
            

        p
            font-size: 15px

        @media (max-width: 700px)
            flex-direction: column

            h2
                text-align: center
                margin-right: 0
                margin-bottom: 16px

    hr
        width: 100%
        border: none
        height: 1px
        background-color: $seperatorColor

    &__statistics
        display: flex
        justify-content: space-between
        margin: 16px 64px 0 64px

        @media (max-width: 700px)
            margin: 16px 0 0 0
        .line
            text-align: center
            hr
                width: clamp(4.2em, 12vw, 8em)
                margin-top: 2px
                margin-bottom: 10px
            span
                &:first-child
                    font-size: clamp(1.6em, 6vw, 3em)
                    letter-spacing: 3px
                    font-weight: 400
                    color: $lightText

                &:last-child
                    font-size: clamp(.8em, 3vw, 1.3em)
                    color: $lightText
                    font-weight: 300


    
